import React from "react";
import { useState } from 'react';
import '../App.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MediaQuery from 'react-responsive';

//Pictures
//Card pics max size 1024 x 556
//carosel pics max size 1024 x 764

import towerCard from '../Assets/towerCard.jpg';
import magicCircleCard from '../Assets/magicCircleCard.jpg';
import spineCard from '../Assets/spineCard.jpg';
import privateCard from '../Assets/privateCard.jpg';
import advPilates1 from '../Assets/advPilates1.jpg';
import advReformer1 from '../Assets/advReformer1.jpg';
import advReformer2 from '../Assets/advReformer2.jpg';
import advReformer3 from '../Assets/advReformer3.jpg';
import beginnerPilates1 from '../Assets/beginnerPilates1.jpg';
import beginnerPilates2 from '../Assets/beginnerPilates2.jpg';
import begReformer1 from '../Assets/begReformer1.jpg';
import begReformer2 from '../Assets/begReformer2.jpg';
import intPilates1 from '../Assets/intPilates1.jpg';
import magicCircle1 from '../Assets/magicCircle1.jpg';
import magicCircle2 from '../Assets/magicCircle2.jpg';
import private1 from '../Assets/private1.jpg';
import private2 from '../Assets/private2.jpg';
import private3 from '../Assets/private3.jpg';
import spine1 from '../Assets/spine1.jpg';
import spine2 from '../Assets/spine2.jpg';
import tower1 from '../Assets/tower1.jpg';
import tower2 from '../Assets/tower2.jpg';
import tower3 from '../Assets/tower3.jpg';
import Helmet from 'react-helmet';
const Classes = () => {
  //Pilates Modal Controls
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  //Reformer Modal Controls
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  //Tower Modal Controls
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  //Magic Circle Modal Controls
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  //Spine Corrector Modal Controls
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  //Private Sessions Modal Controls
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  return (
    <div>
      <h1>
        Classes:
      </h1>
      <Helmet>
      <title> Classes </title>
      <meta
          name="keywords"
          content="Pilates, Reformer, Durham, Classes, Tower, Mat, Studio, Magic Circle, Spine Corrector, Private Session"
        />
      </Helmet>

      <h3>Visit the <a href="https://ShapingFutures.as.me/">booking portal</a> to see our schedule and book onto classes.</h3>
      {/* PC Layout */}
      <MediaQuery minWidth={700}>
        <Row>
          <Col>
            <Card>
              <Card.Img variant="top" src={beginnerPilates1} />
              <Card.Body>
                <h5>
                  <Card.Title>Pilates</Card.Title>
                </h5>
                <Card.Text>
                  <div class="cardContent">
                    The original body weight workout developed by Joseph Pilates.
                  </div>
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="secondary" onClick={handleShow1}>Learn More</Button>
                </div>

              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Img variant="top" src={begReformer1} />
              <Card.Body>
                <h5>
                  <Card.Title>Reformer</Card.Title>
                </h5>
                <Card.Text>
                  Elongate, rebalance, strengthen, and tone.
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="secondary" onClick={handleShow2}>Learn More</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Img variant="top" src={towerCard} />
              <Card.Body>
                <h5>
                  <Card.Title>Tower</Card.Title>
                </h5>
                <Card.Text>
                  Stretching incorporating strength training.
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="secondary" onClick={handleShow3}>Learn More</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <Card.Img variant="top" src={magicCircleCard} />
              <Card.Body>
                <h5>
                  <Card.Title>Magic Circle</Card.Title>
                </h5>
                <Card.Text>
                  Stretch, strengthen, and tone with control.
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="secondary" onClick={handleShow4}>Learn More</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Img variant="top" src={spineCard} />
              <Card.Body>
                <h5>
                  <Card.Title>Spine Corrector</Card.Title>
                </h5>
                <Card.Text>
                  Open your chest, shoulders and hips whilst strengthening your powerhouse.
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="secondary" onClick={handleShow5}>Learn More</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Img variant="top" src={privateCard} />
              <Card.Body>
                <h5>
                  <Card.Title>Private Session</Card.Title>
                </h5>
                <Card.Text>
                  The focus is solely on you.
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="secondary" onClick={handleShow6}>Learn More</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </MediaQuery>


      {/* Phone Layout */}
      <MediaQuery maxWidth={700}>
        <Row>
          <Col>
            <Card>
              <Card.Img variant="top" src={beginnerPilates1} />
              <Card.Body>
                <h5>
                  <Card.Title>Pilates</Card.Title>
                </h5>
                <Card.Text>
                  <div class="cardContent">
                    The original body weight workout developed by Joseph Pilates.
                  </div>
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="secondary" onClick={handleShow1}>Learn More</Button>
                </div>

              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <Card.Img variant="top" src={begReformer1} />
              <Card.Body>
                <h5>
                  <Card.Title>Reformer</Card.Title>
                </h5>
                <Card.Text>
                  Elongate, rebalance, strengthen, and tone.
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="secondary" onClick={handleShow2}>Learn More</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <Card.Img variant="top" src={towerCard} />
              <Card.Body>
                <h5>
                  <Card.Title>Tower</Card.Title>
                </h5>
                <Card.Text>
                  Stretching incorporating strength training.
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="secondary" onClick={handleShow3}>Learn More</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <Card.Img variant="top" src={magicCircleCard} />
              <Card.Body>
                <h5>
                  <Card.Title>Magic Circle</Card.Title>
                </h5>
                <Card.Text>
                  Stretch, strengthen, and tone with control.
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="secondary" onClick={handleShow4}>Learn More</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <Card.Img variant="top" src={spineCard} />
              <Card.Body>
                <h5>
                  <Card.Title>Spine Corrector</Card.Title>
                </h5>
                <Card.Text>
                  Open your chest, shoulders and hips whilst strengthening your powerhouse.
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="secondary" onClick={handleShow5}>Learn More</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <Card.Img variant="top" src={privateCard} />
              <Card.Body>
                <h5>
                  <Card.Title>Private Session</Card.Title>
                </h5>
                <Card.Text>
                  The focus is solely on you!
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="secondary" onClick={handleShow6}>Learn More</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </MediaQuery>
      <Modal show={show1} onHide={handleClose1} size="lg" centred>
        <Modal.Header closeButton>
          <Modal.Title>Pilates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel id={'phoneCarImg'}>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={beginnerPilates1} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={beginnerPilates2} text="First slide" alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={intPilates1} text="Second slide" alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={advPilates1} text="Third slide" alt="Third slide" />
            </Carousel.Item>
          </Carousel>
          <br />
          <p>
            Leila keeps the classes small to ensure you receive personal attention. These classes are taught in 3 levels: beginners, intermediate, and advanced. Following the Classical mat repertoire, Leila designs sessions to strengthen and lengthen without the use of large Pilates equipment. All mat classes are conducted on traditional Pilates mats, equipped with straps and poles to enhance stability and assist with stretching.
          </p>
          <h3>Beginner</h3>
          <p>
            This class is open to everybody; the only requirement is that you should be able to get up and down off a mat. If you have an injury or a condition that requires special attention, it is highly recommended that you participate in a private session before joining a class. This class focuses on building your powerhouse. It is held at Brancepeth Village Hall on Fridays at 9:15 am.
          </p>
          <h3>Intermediate</h3>
          <p>
            Once you have successfully mastered the basic mat order taught in the beginners' classes, you are eligible to join Intermediate classes. Only clients who have been specifically instructed by Leila that they are ready to progress to Intermediate classes may do so. These classes further strengthen your powerhouse. They are held in the Studio on Tuesdays at 10 am and Thursdays at 6:30 pm. Classes can be booked up to 2 weeks in advance and have limited availability. Both Intermediate classes are also available online.
          </p>
          <h3>Advanced</h3>
          <p>
            Participation in the advanced class requires a good grasp of the Intermediate order. Admission is exclusively granted to clients upon direct confirmation from Leila indicating readiness for this level. This class, characterized by its dynamic and continuous flow, calls for a solid level of Powerhouse strength and control. The sessions are conducted in the Studio on Thursdays at 10 am and are also available online for added convenience.
          </p>
        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={handleClose2} size="lg" centred>
        <Modal.Header closeButton>
          <Modal.Title>Reformer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel id={'phoneCar'}>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={begReformer1} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={begReformer2} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={advReformer1} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={advReformer2} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={advReformer3} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
          </Carousel>
          <br />
          <p>
            These sessions are taught in levels of ability: beginners, intermediate, and advanced. To enter any of these sessions, you must have done private sessions with Leila. The sessions follow the classical Reformer order, layering exercises over time.
            <br />
            Following the classical order on the Reformer is an incredibly fun workout. It balances, strengthens, sculpts, and tones while increasing your flexibility. You leave Reformer sessions feeling longer, stronger, and more balanced.
            <br />
            These sessions are very popular and sought after, with only 5 people maximum in any session. To join Reformer, you must commit to a 4-week block initially. At the end of your block, you will then be asked if you want to continue, and if you do, you can proceed as a regular weekly session participant. To join the waiting list, please email info@shapingfutures.co.uk.
          </p>
        </Modal.Body>
      </Modal>

      <Modal show={show3} onHide={handleClose3} size="lg" centred>
        <Modal.Header closeButton>
          <Modal.Title>Tower</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel id={'phoneCar'}>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={tower1} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={tower2} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={tower3} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
          </Carousel>
          <br />
          <p>
            The Tower sessions, renowned for their immense popularity, stand as a favourite among our clientele. Highly esteemed by our clients, these sessions offer an exceptional blend of strength and flexibility training. Tailored for those seeking the Pilates equivalent of a weight class, the Tower sessions surpass expectations with their enjoyable and expansive approach to stretching.
          </p>
          <p>
            Incorporating a comprehensive range of Pilates disciplines, these sessions elevate beyond the ordinary to provide a uniquely enjoyable and effective experience. To participate in these sessions, prior private sessions with Leila are recommended, ensuring participants are well-prepared for the unique demands of the Tower Pilates program. To secure a place, we recommend booking at least 2 weeks in advance to minimise the risk of disappointment due to limited availability.
          </p>
        </Modal.Body>
      </Modal>

      <Modal show={show4} onHide={handleClose4} size="lg" centred>
        <Modal.Header closeButton>
          <Modal.Title>Magic Circle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel id={'phoneCar'}>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={magicCircle1} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={magicCircle2} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
          </Carousel>
          <br />
          <p>
            Joseph Pilates ingeniously transformed a simple beer keg into an innovative piece of Pilates equipment. This versatile tool, perfect for enhancing your Pilates experience, is instrumental in developing core strength and adding resistance to your mat Pilates routines.
          </p>
          <p>
            Our class is designed to target key areas such as the abdominals, legs, inner thighs, hip flexors, and arms. It's an excellent way to build strength and bring a refreshing variety to your Pilates sessions.
          </p>
          <p>
            Magic circles are not only effective but also affordable, making it easy for you to participate in our classes from the comfort of your home if you're unable to visit our studio. Join us and discover the transformative power of this unique Pilates tool.
          </p>
        </Modal.Body>
      </Modal>

      <Modal show={show5} onHide={handleClose5} size="lg" centred>
        <Modal.Header closeButton>
          <Modal.Title>Spine Corrector</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel id={'phoneCar'}>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={spine1} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={spine2} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
          </Carousel>
          <br />
          <p>
            The Spine Corrector, another ingenious creation by Joseph Pilates, lives up to its name by focusing on spinal alignment. This beautifully designed equipment enables the precise execution of the original exercises intended for spine correction.
          </p>
          <p>
            In our dedicated class, each participant enjoys the exclusive use of a Spine Corrector for the entire session. This class is an excellent opportunity to stretch and strengthen your body, focusing deeply on your core muscles. By the end of the class, you'll feel a noticeable difference — standing straighter, feeling longer, and being stronger. Join us for this transformative experience and embrace the benefits of the Spine Corrector.
          </p>

        </Modal.Body>
      </Modal>

      <Modal show={show6} onHide={handleClose6} size="lg" centred dialogClassName="modal-80h">
        <Modal.Header closeButton>
          <Modal.Title>Private Sessions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel id={'phoneCar'}>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={private1} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={private2} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img id={'phoneCarImg'} src={private3} text="Intro slide" alt="Intro slide" />
            </Carousel.Item>
          </Carousel>
          <br />
          <h3>Tailored to your needs</h3>
          <p>Private Pilates sessions cater to individuals with specific goals, injuries, pain, imbalances, or postural concerns. These sessions play an essential role in addressing personalised needs effectively. Before joining any group sessions, it is important to experience a private session. This ensures that, having worked with Leila privately, you are better positioned to gain the most from following group sessions. By familiarising herself with your limitations and imbalances, Leila can provide tailored instructions during group sessions, enhancing the overall experience.</p>
          <h3>Utilising specialized equipment</h3>
          <p>Your initial private session serves as a comprehensive assessment. Leila will evaluate your posture, engage in conversation about your goals, and address your individual needs. This information shapes the session, allowing Leila to apply her expertise in crafting a workout plan tailored specifically to your aspirations and capabilities.</p>
          <h3>Feel Incredible</h3>
          <p>Utilising a variety of specialised equipment customised to your unique needs, private sessions are instrumental in helping you achieve your fitness goals. The goal is to leave each session with a profound sense of accomplishment and well-being. Investing in private sessions unlocks your full potential.</p>

        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Classes;