
import React from "react";
import '../App.css';
import portrait from '../Assets/portrait.jpg';
import MediaQuery from 'react-responsive';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Helmet from 'react-helmet';
const About = () => {
    return (
        <div>
            <h1>
                About Leila
            </h1>
            <Helmet>
            <title> About Us </title>
                <meta
                    name="keywords"
                    content="Pilates, Shaping Futures, Reformer, Durham, Classes, Sessions, Back pain, Studio, North East, About"
                />
            </Helmet>
            <body>
                <MediaQuery minWidth={700}>

                    <Row>
                        <Col />
                        <Col>
                            <img class="image-container-pc" src={portrait} alt="Portrait of Leila with her studio backdrop" />
                        </Col>
                        <Col>
                            <p>
                                <i>
                                    "I love the little moments when a client can touch their toes for the first time, or when they have their first pain free night's sleep.  I love seeing people’s confidence grow astronomically in how they look and feel, and I love watching them move better and sit and stand taller.  I literally have the best job in the world"

                                </i>
                                <br />- Leila Bramwell
                            </p>
                        </Col>
                        <Col />
                    </Row>

                    <br />
                    <div class="text-container">
                        <p>
                            Leila, with over two decades of experience in teaching, is a highly qualified Pilates, health, and fitness instructor. She proudly holds the Romanas Pilates Teacher certification, a distinguished recognition in the world of classical Pilates. This certification is a testament to Leila's deep knowledge and experience, which she brings to each of her sessions.                            </p>
                        <p>
                            Her teaching journey is as diverse as it is extensive, reaching people of all ages. Leila has also made a significant impact in the world of sports, guiding professional athletes to new heights in their careers. More importantly, her teachings have been instrumental in enhancing the quality of life for many, showcasing her dedication to promoting pain-free movement for all.
                        </p>
                        <p>
                            Leila strongly advocates for the importance of accessible, pain-free mobility. She is committed to helping individuals achieve their personal goals, whether that's improving running endurance, working comfortably, keeping up with family, or simply moving better in everyday life. Her approach is characterised by a fun and welcoming manner, ensuring an enjoyable and supportive environment for all. The sustained success and loyalty of her clients, many of whom have been with her for over a decade, are a clear indication of the effectiveness of her approach.
                        </p>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={699}>
                    <div class="image-container-phone">
                        <img grid-column={'1'} grid-row={'1'} src={portrait} alt="Portrait of Leila with her studio backdrop" />
                        <div grid-column={'1'} grid-row={'2'} class="text-container">
                            <p>
                                <i>
                                    "I love the little moments when a client can touch their toes for the first time, or when they have their first pain free night's sleep.  I love seeing people’s confidence grow astronomically in how they look and feel, and I love watching them move better and sit and stand taller.  I literally have the best job in the world"

                                </i>
                                <br />- Leila Bramwell
                            </p>
                            <p>
                                Leila, with over two decades of enriching experience in teaching, is a highly qualified Pilates, health, and fitness instructor. She proudly holds the Romanas Pilates Teacher certification, a distinguished recognition in the world of classical Pilates. This certification is a testament to Leila's deep knowledge and experience, which she brings to each of her sessions.                            </p>
                            <p>
                                Her teaching journey is as diverse as it is extensive, reaching people of all ages. Leila has also made a significant impact in the world of sports, guiding professional athletes to new heights in their careers. More importantly, her teachings have been instrumental in enhancing the quality of life for many, showcasing her dedication to promoting pain-free movement for all.
                            </p>
                            <p>
                                Leila strongly advocates for the importance of accessible, pain-free mobility. She is committed to helping individuals achieve their personal goals, whether that's improving running endurance, working comfortably, keeping up with family, or simply moving better in everyday life. Her approach is characterised by a fun and welcoming manner, ensuring an enjoyable and supportive environment for all. The sustained success and loyalty of her clients, many of whom have been with her for over a decade, are a clear indication of the effectiveness of her approach.
                            </p>
                        </div>
                    </div>
                </MediaQuery>
            </body>
        </div>
    );
};

export default About;