import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/home";
import About from "./pages/about";
import Classes from "./pages/classes";
import Prices from "./pages/prices";
import NoPage from "./pages/nopage";
import Rules from "./pages/rules";
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() { 
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="classes" element={<Classes />} />
          <Route path="prices" element={<Prices />} />
          <Route path="rules" element={<Rules />} />
          <Route path="*" element={<NoPage />} /> 
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

//path * is a great 404 page
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);