import React, { useEffect } from "react";
import '../App.css';
import hero from '../Assets/hero.jpg';
import Insta from '../Assets/shaping.futures_qr.png';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MediaQuery from 'react-responsive';
import Helmet from 'react-helmet';


const Home = () => {
  return (
    <div>
      <div class="Hero">
      </div>
      <Helmet>
      <meta
          name="keywords"
          content="Pilates, Shaping Futures, Reformer, Durham, Classes, Sessions, Back pain, Studio, North East"
        />
      </Helmet>
      
        <h1>
           Welcome to Shaping Futures
        </h1>
      
      <img id='hero' src={hero} alt="Leila and her designer studio"/>
      <div id='homeDescription'>
          <h2>
            The only Classical Romanas Pilates Studio in the North East.
          </h2>
          <h3>
            If you desire the benefits of Pilates, you've found the right place.
            <br />
            Shaping Futures offers award winning Pilates in small group sessions and private lessons.
            Whether you're aiming to enhance quality of life or improve athletic performance, our studio caters to everyone. Start your Pilates journey with us or get in touch and let's take it to the next level.
          </h3>
          
        <body>
          <div id="fb-root"></div>
          <h2>
            Currently at Shaping Futures:
          </h2>
          <MediaQuery minWidth={551}>
            <Row >
              <Col />
              <Col class="col-4 d-flex justify-content-center" style={{display:'flex', justifyContent:'right'}}>
                <iframe title='fbPage' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPilatesstudioDurham&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="500" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              </Col>
              <Col>
                <a href={'https://www.instagram.com/shaping.futures/'}>
                  <img id='Insta1' src={Insta} alt="Instagram QR code and link"/>
                </a>
              </Col>
              <Col />
            </Row>
          </MediaQuery>
          <MediaQuery maxWidth={550}>
            <Col >
              <Row >
                <iframe title='fbPage' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPilatesstudioDurham&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="500" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              </Row>
              <Row>
                <a href={'https://www.instagram.com/shaping.futures/'}>
                  <img id='Insta2' src={Insta} alt="Instagram QR code and link"/>
                </a>
              </Row>
            </Col>
          </MediaQuery>
        </body>

      </div>
    </div>
  );
};

export default Home;