import { Outlet, Link } from "react-router-dom";
import '../Navbar.css'
import Logo from '../Assets/front-logo.png'
import closeButton from '../Assets/close-icon.png'
import closeButton1 from '../Assets/close-icon-black.png'
import menuIcon from '../Assets/menu-icon-white.png'
import menuIcon1 from '../Assets/menu-icon-black.png'
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import MediaQuery from 'react-responsive';
import Helmet from 'react-helmet';

function Layout() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div class="layout">
                  <Helmet>
                <meta
                    name="keywords"
                    content="Pilates, Shaping Futures, Reformer, Durham, Classes, Sessions, Back pain, Studio, North East, Prices"
                />
            </Helmet>
      <div class="fixed-header">
        
        <div class="header-content">
          <img src={Logo} alt="Logo" width="260" height="50" />
          <MediaQuery minWidth={550}>
            <div class="header-text">Contact: 07903 700856<br />info@shapingfutures.co.uk</div>
          </MediaQuery>
        </div>
        <img id="menuIcon" src={menuIcon} alt="Menu Icon" onClick={handleShow} onMouseOver={e => (e.currentTarget.src = menuIcon1)} onMouseOut={e => (e.currentTarget.src = menuIcon)} />
      </div>
      <MediaQuery minWidth={550}>
        <Offcanvas id= 'desktopNavbar' show={show} placement={'end'} onHide={handleClose}>
          <Offcanvas.Header>
            <img id="closeButton" src={closeButton} alt="Close Button" onClick={handleClose} onMouseOver={e => (e.currentTarget.src = closeButton1)} onMouseOut={e => (e.currentTarget.src = closeButton)} />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul id={'desktopMenu'}>
              <li> <Link class="link" to="/" onClick={handleClose}>Home</Link> </li>
              <li> <Link class="link" to="/about" onClick={handleClose}>About Leila</Link> </li>
              <li> <Link class="link" to="/classes" onClick={handleClose}>Classes</Link> </li>
              <li> <Link class="link" to="/prices" onClick={handleClose}>Prices</Link> </li>
              <li> <Link class="link" to="/rules" onClick={handleClose}>Rules</Link> </li>
              <li> <Link class="link" to="https://ShapingFutures.as.me/" onClick={handleClose}>Bookings</Link> </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </MediaQuery>
      <MediaQuery maxWidth={550}>
        <Offcanvas id= 'phoneNavbar' show={show} placement={'end'} onHide={handleClose}>
          <Offcanvas.Header>
            <img id="closeButton" src={closeButton} alt="Close Button" onClick={handleClose} onMouseOver={e => (e.currentTarget.src = closeButton1)} onMouseOut={e => (e.currentTarget.src = closeButton)} />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul id={'phoneMenu'}>
              <li> <Link class="link" to="/" onClick={handleClose}>Home</Link> </li>
              <li> <Link class="link" to="/about" onClick={handleClose}>About Leila</Link> </li>
              <li> <Link class="link" to="/classes" onClick={handleClose}>Classes</Link> </li>
              <li> <Link class="link" to="/prices" onClick={handleClose}>Prices</Link> </li>
              <li> <Link class="link" to="/rules" onClick={handleClose}>Rules</Link> </li>
              <li> <Link class="link" to="https://ShapingFutures.as.me/" onClick={handleClose}>Bookings</Link> </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </MediaQuery>
      <Outlet />
    </div>
  )
};

export default Layout;