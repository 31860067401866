import React from "react";
import '../App.css';
import Helmet from 'react-helmet';

const Prices = () => {
    return (
        <div>
            <h1>
                Price List
            </h1>
            <Helmet>
            <title> Prices </title>
                <meta
                    name="keywords"
                    content="Pilates, Shaping Futures, Reformer, Durham, Classes, Sessions, Back pain, Studio, North East, Prices"
                />
            </Helmet>
            <body>
                <ul>
                    <h3>Visit the <a href="https://ShapingFutures.as.me/">booking portal</a> to see our schedule and book onto classes.</h3>
                    < br />
                    <li><b>Pilates Mat</b> – Beginners and Intermediate and Magic Circle</li>
                    <ul>
                        <li>Single class: £16 </li>
                        <li>Block of 5: £75 </li>
                        <li>Block of 10: £140 (6 months to use your block from purchase)</li>
                    </ul>

                    <li><b>Pilates Advanced</b> (Small group)</li>
                    <ul>
                        <li>Single class: £30 </li>
                    </ul>

                    <li><b>Reformer</b> – all levels</li>
                    <ul>
                        <li>Single class: £30 </li>
                    </ul>

                    <li><b>Tower</b></li>
                    <ul>
                        <li>Single class: £30 </li>
                        <li>1st block of 4: £120 </li>
                    </ul>

                    <li><b>Spine Corrector</b></li>
                    <ul>
                        <li>Single class: £20 </li>
                    </ul>

                    <li><b>Private Sessions</b> (Solo)</li>
                    <ul>
                        <li>50 minute session: £80 </li>
                        <li>30 minute session: £40 </li>
                        <li>Block of 10: £720</li>
                        <li>Please contact Leila to arrange a private session.</li>
                    </ul>
                    <li><b>Private Duet</b> (Two people)</li>
                    <ul>
                        <li>50 minute session: £100 </li>
                        <li>Please contact Leila to arrange a private duet.</li>

                    </ul>
                </ul>
            </body>
        </div>
    );
};

export default Prices;