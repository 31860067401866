import React from "react";
import '../App.css';
import parq from '../Assets/PAR Q 2024.2.pdf'
import Helmet from 'react-helmet';

const Rules = () => {
    return (
        <div>
            <h1>
                Rules
            </h1>
            <Helmet>
            <title> Rules </title>
                <meta
                    name="keywords"
                    content="Pilates, Shaping Futures, Reformer, Durham, Classes, Sessions, Back pain, Studio, North East, Rules"
                />
            </Helmet>
            <body>
                <ol>
                    <li>Everyone must fill in a ParQ before taking part in the Studio or online. <a href={parq} download='SF ParQ Form'>Download ParQ Form Here</a></li>
                    <li>Please wear clean socks in the Studio, no shoes or bare feet.</li>
                    <li>Please give at least 48 hours notice if you need to cancel. You will be charged 100% if you do not give a full 48 hours notice. </li>
                    <li>Once booked payment is taken the Wednesday the week before your appointments so if you cancel after that time payment will be stored on your account.  Please note you cannot change a private for classes and vice versa.</li>
                    <li>I have a strict - no coming in the studio if you are unwell policy or if you have a cold or any cold like symptoms.  There are many autoimmune people coming into the studio who would take very ill if they caught your cold or virus.  Similarly if you have had a sick or stomach bug please do not come to the Studio for at least 72 hrs after you no longer have symptoms.  Shaping Futures is a safe space so please help all of us keep it that way. </li>
                    <li>Please tell me if you have any new injuries or new health concerns if they have developed after your ParQ. </li>
                    <li>Please drive down Foxes Row very slow carefully and mindful of my neighbours. </li>
                    <li>Parking is available on the main road in Brancepeth or if you are parking directly outside the Studio please try not to block anyone in. </li>
                    <li>Try not to come in the Studio more than 5 minutes early to your session as people may not wish for you to watch them in their sessions.   The Studio may also not be open if you are the 1st session in the morning or afternoon.</li>
                </ol>









            </body>
        </div>
    );
};

export default Rules;