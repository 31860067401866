import React from "react";
import { Outlet, Link } from "react-router-dom";
import '../App.css'
const NoPage = () => {
    return (
      <div>
        <h1>404</h1>
        <body>I think you're a bit lost... Return  <Link class="link" to="/" >Home</Link> </body>
      </div>
    );
  };
  
  export default NoPage;